<template>
  <div class="">

    <header class="jumbotron">

      <div class="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div class="absolute inset-0">
          <div class="h-1/3 sm:h-2/3"></div>
        </div>
        <div class="relative max-w-7xl mx-auto">
          <div class="text-center">
            <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              Interne updates
            </h2>
          </div>
          <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            
            <div class="flex flex-col rounded-lg shadow-lg overflow-hidden" v-for="item in content" :key="item.id">
              <div class="flex-shrink-0">
                <DynamicImage :source="item.Photos" type="800x500x" v-if="item.Photos"/>
              </div>
              <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                <div class="flex-1">
                  <p class="text-sm font-medium text-orange-600">
                    <router-link :to="`/internal/${item.slug}`" class="hover:underline">
                      Update
                    </router-link>
                  </p>
                  <router-link :to="`/internal/${item.slug}`" class="hover:underline">
                    <p class="text-xl font-semibold text-gray-900">
                      {{item.title}}
                    </p>
                    <!--<p class="mt-3 text-base text-gray-500" v-html="item.description"></p>-->
                  </router-link>
                </div>
                <div class="mt-4 flex items-center">
                  <div class="flex space-x-1 text-sm text-gray-500">
                    {{item.intro}}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <Pagination v-if="content.length > 0" :page="page" :pages="pages" :itemCount="itemCount" :pageSize="pageSize" @next="next" @previous="previous" />

    </header>
  </div>
</template>

<script>
import InternalNewsService from '../../../services/admin/internal/service';
import DynamicImage from '../../../components/DynamicImage.vue'
import Pagination from '../../../components/Pagination.vue';
import moment from 'moment';

export default {
  name: 'RecentInteralNews',
  components: {
    DynamicImage,
    Pagination
  },
  data() {
    return {
      content: [],
      searchQuery: null,
      page: 1,
      pages: 0,
      itemCount: 0,
      pageSize: 9,
      loading: false
    };
  },
  created: function () {
    this.moment = moment;
  },
  methods: {
    getData() {
      InternalNewsService.getItems(this.page, this.pageSize).then(
        response => {
          this.content = response.data.items;
          this.pages = response.data.pageCount;
          this.itemCount = response.data.itemCount
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    reset() {
      this.pages = 0;
      this.itemCount = 0;
    },
    clear() {
      this.searchQuery = null;
      this.reset();
      this.getData();
    },
    search() {
      this.reset();
      this.getData();
    },
    next() {
      this.page = this.page + 1;
      this.getData();
    },
    previous() {
      this.page = this.page - 1;
      this.getData();
    }
  },
  mounted() {
    this.getData();
  }
};
</script>
