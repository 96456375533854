import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_BASE_URL;

class UserService {
  
  getUpdates(page, limit) {
    if (!page) page = 1;
    if (!limit) limit = 9;
    return axios.get(API_URL + `updates?page=${page}&limit=${limit}`, { headers: authHeader() });
  }

  searchColleagues(query) {
    return axios.get(API_URL + `people/search?q=${query}`, { headers: authHeader() });
  }

  getColleague(id) {
    return axios.get(API_URL + `people/search/${id}`, { headers: authHeader() });
  }

  getUserBoard() {
    return axios.get(API_URL + 'admin/people', { headers: authHeader() });
  }

  getPeople() {
    return axios.get(API_URL + 'admin/people', { headers: authHeader() });
  }

  getBusinessUnits() {
    return axios.get(API_URL + 'admin/business_units', { headers: authHeader() });
  }

  getTags() {
    return axios.get(API_URL + 'admin/tags', { headers: authHeader() });
  }

  getJobTags() {
    return axios.get(API_URL + 'admin/job_tags', { headers: authHeader() });
  }

  getClientLogos() {
    return axios.get(API_URL + `admin/client_logos?page=1&limit=1000`, { headers: authHeader() });
  }

  getHomepageItems() {
    return axios.get(API_URL + 'admin/homepage_items', { headers: authHeader() });
  }
  
  getHomepageItem(id) {
    return axios.get(API_URL + `admin/homepage_items/${id}`, { headers: authHeader() });
  }

  saveHomepageItem(c) {
    return axios.post(API_URL + `admin/homepage_items/${c.id}`, {...c}, { headers: authHeader() });
  }

}

export default new UserService();
