<template>
  <nav
    class="
      px-4
      py-3
      flex
      items-center
      justify-between
      border-t border-gray-200
      sm:px-6
    "
    aria-label="Pagination"
  >
    <div class="hidden sm:block">
      <p class="text-sm text-gray-700">
        Showing
        <span class="font-medium">{{ (page * pageSize) - pageSize + 1 }}</span>
        to
        <span class="font-medium">{{ page * pageSize }}</span>
        of
        <span class="font-medium">{{ itemCount }}</span>
        results
      </p>
    </div>
    <div class="flex-1 flex justify-between sm:justify-end">
      <button
        class="
          relative
          inline-flex
          items-center
          px-4
          py-2
          border border-gray-300
          text-sm
          font-medium
          rounded-md
        "
        :class="page === 1 ? 'text-gray-300 bg-white cursor-not-allowed' : 'text-gray-700 bg-white hover:bg-gray-50'"
        @click="previous"
        :disabled="page === 1"
      >
        Previous
      </button>
      <button
        class="
          ml-3
          relative
          inline-flex
          items-center
          px-4
          py-2
          border border-gray-300
          text-sm
          font-medium
          rounded-md
        "
        :class="
          page === pages
            ? 'text-gray-300 bg-white cursor-not-allowed'
            : 'text-gray-700 bg-white hover:bg-gray-50'
        "
        @click="next"
        :disabled="page === pages"
      >
        Next
      </button>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Pagination",
  props: ["page", "pages", "itemCount", "pageSize"],
  methods: {
    next() {
      this.$emit("next");
    },
    previous() {
      this.$emit("previous");
    },
  },
};
</script>
