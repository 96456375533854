<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
  
  <div class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-3 border rounded bg-gray-100">
    <div class="lg:grid lg:grid-cols-2 lg:gap-8">
      <div class="sm:text-center md:max-w-2xl md:mx-auto md:col-span-1 lg:text-left my-6">
        <h1>
          <span class="mt-1 block text-2xl tracking-tight font-extrabold sm:text-5xl xl:text-4xl">
            <span class="text-gray-900 mr-2">Zoek en vind je</span>
            <span class="text-orange-500">collega's</span>
          </span>
        </h1>
        <p class="text-base text-gray-500 sm:mt-2 sm:text-xl lg:text-lg">
          Vul je eigen <router-link to="/profile" class="text-orange-600">profiel</router-link> aan met o.a. je telefoonnummer zodat je nog makkerlijker te vinden bent.
        </p>
      </div>
      <div class="mt-12 lg:mt-0 md:col-span-1 flex flex-col">
        <div class="py-8">
        <p class="text-base font-medium text-gray-400">
          Zoek op voor- of achternaam (of telefoonnummer)
        </p>
        <form @submit.prevent="searchColleague" class="mt-3 w-100 sm:flex">
          <label for="email" class="sr-only">Email</label>
          <input type="text" class="block w-full py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-black focus:border-black sm:flex-1 border-gray-300" v-model="peopleQuery">

          <button type="submit" class="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto">
            <svg v-show="loading" class="animate-spin h-4 mr-2 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white;" viewBox="0 0 24 24"></svg>
            {{loading ? 'zoekt': 'zoek'}}
          </button>
        </form>
        </div>

        <ul role="list" class="space-y-6 mb-4 w-full" v-if="people">
          <li v-for="item, index in people" :key="index" class="bg-white rounded p-4 shadow m-0">
            <div class="flex items-center space-x-4 lg:space-x-6">
              <DynamicImage v-if="item.photo" :source="item.photo" class="w-16 h-16 rounded-full lg:w-20 lg:h-20" />
              <div class="leading-6 space-y-1">
                <h3 class="text-lg font-medium text-black font-bold mb-4">{{item.first_name}} {{item.last_name}}</h3>
                <span v-if="item.phone">
                  <a :href="'tel:'+item.phone" class="bg-white rounded-full hover:bg-orange-500 hover:text-white border-2 mr-4 mb-4 border-orange-500 text-gray-800 font-bold py-1 px-4 rounded inline-flex items-center">
                    <PhoneOutgoingFilled32 class="fill-current w-4 h-4 mr-2"/>
                    <span>Bel</span>
                  </a>
                </span>
                <span v-if="item.email">
                  <a :href="'mailto:'+item.email" class="bg-white rounded-full hover:bg-orange-500 hover:text-white border-2 mr-4 mb-4 border-orange-500 text-gray-800 font-bold py-1 px-4 rounded inline-flex items-center">
                    <Email32 class="fill-current w-4 h-4 mr-2"/>
                    <span>Email</span>
                  </a>
                </span>
                <span>
                  <router-link :to="`/smoelenboek/`+item.objectID.replace('user_', '')" class="hover:bg-white text-white rounded-full bg-orange-500 text-white border-2 mr-4 mb-4 border-orange-500 hover:text-gray-800 font-bold py-1 px-4 rounded inline-flex items-center">
                    <InformationFilled32 class="fill-current w-4 h-4 mr-2"/>
                    <span>Details</span>
                  </router-link>
                </span>
                <div v-if="item.businessUnits">
                  <span v-for="(bu, index) in item.businessUnits" :key="index" class="rounded-full bg-gray-900 text-white px-2 py-1 mr-2">
                    {{bu.title.toLowerCase()}}
                  </span>
                </div>
                <router-link :to="'/smoelenboek/'+item.objectID.replace('user_', '')" class="text-orange-600">details</router-link>
              </div>
            </div>
          </li>
        </ul>

      </div>
    </div>
  </div>

  <RecentUserList class="mt-4" />
  <InteralNewsList />

    <header class="jumbotron">

      <div class="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div class="absolute inset-0">
          <div class="h-1/3 sm:h-2/3"></div>
        </div>
        <div class="relative max-w-7xl mx-auto">
          <div class="text-center">
            <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              Recente updates {{isAuthenticated}}
            </h2>
            <p class="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              De meest recente updates op de website.
            </p>
          </div>
          <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            
            <div class="flex flex-col rounded-lg shadow-lg overflow-hidden" v-for="item in content" :key="item.id">
              <div class="flex-shrink-0">
                <DynamicImage :source="item.Photos" type="800x500x" />
              </div>
              <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                <div class="flex-1">
                  <p class="text-sm font-medium text-orange-600">
                    <a :href="`https://mccoy-partners.com/updates/${item.slug}`" class="hover:underline" target="_blank">
                      Article
                    </a>
                  </p>
                  <a :href="`https://mccoy-partners.com/updates/${item.slug}`" target="_blank" class="block mt-2">
                    <p class="text-xl font-semibold text-gray-900">
                      {{item.title}}
                    </p>
                    <!--<p class="mt-3 text-base text-gray-500" v-html="item.description"></p>-->
                  </a>
                </div>
                <div class="mt-6 flex items-center">
                  <div class="flex-shrink-0">
                    <a href="#" v-if="item.User">
                      <span class="sr-only">{{item.User.first_name}} {{item.User.last_name}}</span>
                      <img class="h-10 w-10 rounded-full" src="@/assets/blank-profile.webp" alt="">
                    </a>
                  </div>
                  <div class="ml-3">
                    <p class="text-sm font-medium text-gray-900">
                      <a href="#" class="hover:underline" v-if="item.User">
                        {{item.User.first_name}} {{item.User.last_name}}
                      </a>
                    </p>
                    <div class="flex space-x-1 text-sm text-gray-500">
                      <time datetime="2020-03-16">
                        {{moment(item.created_at).format('DD-MM-YYYY')}}
                      </time>    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Pagination v-if="content.length > 0" :page="page" :pages="pages" :itemCount="itemCount" :pageSize="pageSize" @next="next" @previous="previous" />

    </header>
  </div>
</template>

<script>
import UserService from '../services/user.service';
import InteralNewsList from '../components/admin/internal_updates/List';
import RecentUserList from '../components/admin/recent_users/List';
import DynamicImage from '../components/DynamicImage.vue'
import Pagination from '../components/Pagination';
import moment from 'moment';
import { PhoneOutgoingFilled32, Email32, InformationFilled32 } from '@carbon/icons-vue';
import { useIsAuthenticated } from "../composition-api/useIsAuthenticated";


export default {
  name: 'Home',
  components: {
    DynamicImage,
    Pagination,
    Email32,
    PhoneOutgoingFilled32,
    InformationFilled32,
    InteralNewsList,
    RecentUserList
  },
  data() {
    return {
      people: [],
      peopleQuery: '',
      content: [],
      searchQuery: null,
      page: 1,
      pages: 0,
      itemCount: 0,
      pageSize: 9,
      loading: false,
      isAuthenticated: useIsAuthenticated()
    };
  },
  created: function () {
    this.moment = moment;
  },
  methods: {
    getData() {
      UserService.getUpdates(this.page, this.pageSize).then(
        response => {
          this.content = response.data.items;
          this.pages = response.data.pageCount;
          this.itemCount = response.data.itemCount
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          if (error.response.status === 401)
            this.$router.push(`/auth`);
        }
      );
    },
    searchColleague() {
      
      if (this.peopleQuery === '') {
        this.people = [];
      } else {
        this.loading = true;
        UserService.searchColleagues(this.peopleQuery).then(
        response => {
          this.people = response.data.hits;
          this.loading = false;
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
            this.loading = false;
        }
      );
      }
      
    },
    reset() {
      this.pages = 0;
      this.itemCount = 0;
    },
    clear() {
      this.searchQuery = null;
      this.reset();
      this.getData();
    },
    search() {
      this.reset();
      this.getData();
    },
    next() {
      this.page = this.page + 1;
      this.getData();
    },
    previous() {
      this.page = this.page - 1;
      this.getData();
    }
  },
  mounted() {
    this.getData();
  }
};
</script>
