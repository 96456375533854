import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class RecentUserService {
  
  getItems() {
    return axios.get(API_URL + `admin/recent_users`, { headers: authHeader() });
  }

  getUserDetails(id) {
    return axios.get(API_URL + `admin/recent_users/${id}`, { headers: authHeader() });
  }

}

export default new RecentUserService();
