<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div aria-live="assertive" class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-if="show" class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckmarkFilled32 class="h-6 w-6 text-green-400" aria-hidden="true" v-if="type === 'success'" />
                <WarningFilled32 class="h-6 w-6 text-yellow-400" aria-hidden="true" v-if="type === 'warning'" />
                <WarningFilled32 class="h-6 w-6 text-red-400" aria-hidden="true" v-if="type === 'danger'" />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  {{message}}
                </p>
                <p class="mt-1 text-sm text-gray-500">
                  {{description}}
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button @click="show = false" class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                  <span class="sr-only">Close</span>
                  <Close32 class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { WarningFilled32, CheckmarkFilled32, Close32 } from '@carbon/icons-vue';

export default {
  name: 'Notification',
  components: {
    Close32,
    WarningFilled32,
    CheckmarkFilled32
  },
  data() {
    return {
      account: null,
      show: false,
      type: null,
      message: null,
      duration: 2000
    };
  },
  watch: {
    show() {
      setTimeout(( ) => {
        this.show = false;
      }, this.duration)
    }
},
  mounted() { 
    this.emitter.on("notification", ({type, message, description = '', duration = 2000}) => {
      this.show = true;
      this.type = type;
      this.message = message;
      this.description = description
      this.duration = duration
    });
  }
}
</script>