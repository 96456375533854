import store from '../store';

export default function authHeader() {
  const token = store.getters.accesstoken;
  if (token) {
    // for Node.js Express back-end
    return { 'Authorization': "bearer " + token };
  } else {
    return {};
  }
}
