import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class InternalNewsService {
  getItems(page, limit, query) {
    if (query) {
      return axios.get(API_URL + `admin/internal?page=${page}&limit=${limit}&q=${query}`, { headers: authHeader() });
    } else {
      return axios.get(API_URL + `admin/internal?page=${page}&limit=${limit}`, { headers: authHeader() });
    }
  }

  getNewsItem(id) {
    return axios.get(API_URL + `admin/internal/${id}`, {
      headers: authHeader(),
    });
  }

}

export default new InternalNewsService();
