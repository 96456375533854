<template>
  <div class="grid grid-cols-1 gap-4 sm:grid-cols-3">
    <router-link :to="`/smoelenboek/`+user.id" v-for="user in recentUsers" :key="user.ms_oid" class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-500">
      <div class="flex-shrink-0">
        <DynamicImage :source="user.Photos" type="600x600x" class="h-10 w-10 rounded-full" />
      </div>
      
      <div class="flex-1 min-w-0">
        <div class="focus:outline-none cursor-pointer">
          <span class="absolute inset-0" aria-hidden="true" />
          <p class="text-sm font-medium text-gray-900">
            {{ user.first_name }} {{ user.last_name }}
          </p>
          <p class="text-sm text-gray-500 truncate mt-1">
            <template v-if="user.BusinessUnits">
              <span v-for="(bu, index) in user.BusinessUnits" :key="index" class="rounded-full bg-gray-900 text-white px-2 py-1 mr-2">
                {{bu.title.toLowerCase()}}
              </span>
            </template>
          </p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import RecentUserService from '../../../services/admin/users/recent_user_service';
import DynamicImage from '../../../components/DynamicImage.vue'
import { ref, onMounted } from "vue";

export default {
  components: {
    DynamicImage
  },
  setup() {
    const recentUsers = ref(null);
    const modalIsOpen= ref(false);
    const currentIntro = ref('x');

    const fetchAllRecentUsers = async function() {
      await RecentUserService.getItems().then(({data})=>{
        recentUsers.value = data;
      });
    }

    const setIntro = function(intro) {
      currentIntro.value = intro;
    }

    onMounted(() => {
      fetchAllRecentUsers();
    });

    return {
      recentUsers,
      modalIsOpen,
      setIntro,
      currentIntro
    }
  },
  methods: {
    toggleModal(intro) {
      this.setIntro(intro);
      this.modalIsOpen = !this.modalIsOpen;
    }
  }
}
</script>