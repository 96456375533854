import { createWebHistory, createRouter } from "vue-router";
import Home from "../views/Home.vue";
import NotFound from "../views/NotFound.vue";
import Failed from "../views/Failed.vue";
// import AuthService from "../services/auth.service";
import { registerGuard } from "./Guard";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "McCoy Portal - Home",
      layout: "AppLayoutPortal",
      requiresAuth: true
    }
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("../views/auth.vue"),
    meta: {
      title: "McCoy Portal - Auth",
      layout: "AppLayoutDefault",
    },
  },
  {
    path: "/changelog",
    name: "Changelog",
    component: () => import("../views/admin/changelog.vue"),
    meta: {
      title: "McCoy Portal - Changelog",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/settings",
    name: 'settings',
    component: () => import("../views/admin/settings/index.vue"),
    meta: {
      title: "McCoy Portal - Settings",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/pages",
    name: "pages",
    component: () => import("../views/admin/pages/Overview.vue"),
    meta: {
      title: "McCoy Portal - pages",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/pages/:id",
    name: "page",
    component: () => import("../views/admin/pages/Detail.vue"),
    meta: {
      title: "McCoy Portal - page",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/pages/new",
    name: "New page",
    component: () => import("../views/admin/pages/New.vue"),
    meta: {
      title: "McCoy Portal - New page",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/people",
    name: "People",
    component: () => import("../views/admin/people/Overview.vue"),
    meta: {
      title: "McCoy Portal - People",
      layout: "AppLayoutPortal",
    },
  },
  {
    path: "/people/:id",
    name: "Person",
    component: () => import("../views/admin/people/Detail.vue"),
    meta: {
      title: "McCoy Portal - Person",
      layout: "AppLayoutPortal",
    },
  },
  {
    path: "/people/new",
    name: "New person",
    component: () => import("../views/admin/people/New.vue"),
    meta: {
      title: "McCoy Portal - New Person",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/client_logos",
    name: "client logos",
    component: () => import("../views/admin/client_logos/Overview.vue"),
    meta: {
      title: "McCoy Portal - client logos",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/client_logos/:id",
    name: "client_logo",
    component: () => import("../views/admin/client_logos/Detail.vue"),
    meta: {
      title: "McCoy Portal - client logo",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/client_logos/new",
    name: "New client logo",
    component: () => import("../views/admin/client_logos/New.vue"),
    meta: {
      title: "McCoy Portal - New client logo",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/videos",
    name: "videos",
    component: () => import("../views/admin/videos/Videos.vue"),
    meta: {
      title: "McCoy Portal - videos",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/videos/:id",
    name: "video",
    component: () => import("../views/admin/videos/VideoDetail.vue"),
    meta: {
      title: "McCoy Portal - video",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/videos/new",
    name: "New video",
    component: () => import("../views/admin/videos/New.vue"),
    meta: {
      title: "McCoy Portal - New client video",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("../views/admin/users/Index.vue"),
    meta: {
      title: "McCoy Portal - Users",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/users/:id",
    name: "User",
    component: () => import("../views/admin/users/Edit.vue"),
    meta: {
      title: "McCoy Portal - User",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/users/new",
    name: "New user",
    component: () => import("../views/admin/users/New.vue"),
    meta: {
      title: "McCoy Portal - New user",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/quotes",
    name: "quotes",
    component: () => import("../views/admin/quotes/Index.vue"),
    meta: {
      title: "McCoy Portal - quotes",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/quotes/:id",
    name: "quote",
    component: () => import("../views/admin/quotes/Edit.vue"),
    meta: {
      title: "McCoy Portal - quote",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/quotes/new",
    name: "New quote",
    component: () => import("../views/admin/quotes/New.vue"),
    meta: {
      title: "McCoy Portal - New quote",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/leaders",
    name: "leaders",
    component: () => import("../views/admin/leaders/Index.vue"),
    meta: {
      title: "McCoy Portal - leaders",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/leaders/:id",
    name: "leader",
    component: () => import("../views/admin/leaders/Edit.vue"),
    meta: {
      title: "McCoy Portal - leader",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/leaders/new",
    name: "New leader",
    component: () => import("../views/admin/leaders/New.vue"),
    meta: {
      title: "McCoy Portal - New leader",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/tags",
    name: "tags",
    component: () => import("../views/admin/tags/Index.vue"),
    meta: {
      title: "McCoy Portal - tags",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/tags/:id",
    name: "tag",
    component: () => import("../views/admin/tags/Edit.vue"),
    meta: {
      title: "McCoy Portal - tag",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/tags/new",
    name: "New tag",
    component: () => import("../views/admin/tags/New.vue"),
    meta: {
      title: "McCoy Portal - New tag",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/hubspot",
    name: "hubspots",
    component: () => import("../views/admin/hubspot/Index.vue"),
    meta: {
      title: "McCoy Portal - hubspot",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/hubspot/:id",
    name: "hubspot",
    component: () => import("../views/admin/hubspot/Edit.vue"),
    meta: {
      title: "McCoy Portal - hubspot",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/hubspot/new",
    name: "New hubspot campaign",
    component: () => import("../views/admin/hubspot/New.vue"),
    meta: {
      title: "McCoy Portal - New campaign",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/redirects",
    name: "redirects",
    component: () => import("../views/admin/route_rules/Index.vue"),
    meta: {
      title: "McCoy Portal - redirects",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/redirects/:id",
    name: "redirect",
    component: () => import("../views/admin/route_rules/Edit.vue"),
    meta: {
      title: "McCoy Portal - redirects",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/redirects/new",
    name: "New redirects campaign",
    component: () => import("../views/admin/route_rules/New.vue"),
    meta: {
      title: "McCoy Portal - New redirect",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/job_tags",
    name: "jobtags",
    component: () => import("../views/admin/job_tags/Index.vue"),
    meta: {
      title: "McCoy Portal - job_tags",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/job_tags/:id",
    name: "job_tag",
    component: () => import("../views/admin/job_tags/Edit.vue"),
    meta: {
      title: "McCoy Portal - job_tag",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/job_tags/new",
    name: "New job_tags",
    component: () => import("../views/admin/job_tags/New.vue"),
    meta: {
      title: "McCoy Portal - New job_tag",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/business_units",
    name: "business_units",
    component: () => import("../views/admin/business_units/Index.vue"),
    meta: {
      title: "McCoy Portal - Business Units",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/business_units/:id",
    name: "business_unit",
    component: () => import("../views/admin/business_units/Edit.vue"),
    meta: {
      title: "McCoy Portal - Business Unit",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/business_units/new",
    name: "new_business_unit",
    component: () => import("../views/admin/business_units/New.vue"),
    meta: {
      title: "McCoy Portal - New Business Units",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/call_to_actions",
    name: "Ctas",
    component: () => import("../views/admin/call_to_actions/Overview.vue"),
    meta: {
      title: "McCoy Portal - ctas",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/call_to_actions/tiny_select",
    name: "CtasSelect",
    component: () => import("../views/admin/call_to_actions/tiny_select.vue"),
    meta: {
      title: "McCoy Portal - ctas",
      layout: "AppLayoutDefault",
      requiresAuth: true
    },
  },
  {
    path: "/call_to_actions/:id",
    name: "Cta",
    component: () => import("../views/admin/call_to_actions/Detail.vue"),
    meta: {
      title: "McCoy Portal - cta",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/call_to_actions/new",
    name: "New call_to_action",
    component: () => import("../views/admin/call_to_actions/New.vue"),
    meta: {
      title: "McCoy Portal - New cta",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cases",
    name: "Success stories",
    component: () => import("../views/admin/cases/Cases.vue"),
    meta: {
      title: "McCoy Portal - Cases",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cases/:id",
    name: "Success story",
    component: () => import("../views/admin/cases/CaseDetail.vue"),
    meta: {
      title: "McCoy Portal - Case",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cases/new",
    name: "New case",
    component: () => import("../views/admin/cases/New.vue"),
    meta: {
      title: "McCoy Portal - New case",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/news_items",
    name: "news_items",
    component: () => import("../views/admin/news_items/Overview.vue"),
    meta: {
      title: "McCoy Portal - Cases",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/news_items/:id",
    name: "news_item",
    component: () => import("../views/admin/news_items/Detail.vue"),
    meta: {
      title: "McCoy Portal - Case",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/news_items/new",
    name: "new_news_item",
    component: () => import("../views/admin/news_items/New.vue"),
    meta: {
      title: "McCoy Portal - Newsitem",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/internal_updates",
    name: "internal_updates",
    component: () => import("../views/admin/internal_updates/Overview.vue"),
    meta: {
      title: "McCoy Portal - internal_updates",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/internal_updates/:id",
    name: "internal_update",
    component: () => import("../views/admin/internal_updates/Detail.vue"),
    meta: {
      title: "McCoy Portal - internal_update",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/internal_updates/new",
    name: "new_internal_update",
    component: () => import("../views/admin/internal_updates/New.vue"),
    meta: {
      title: "McCoy Portal - new internal update",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/internal",
    name: "internals",
    component: () => import("../views/admin/internal/Overview.vue"),
    meta: {
      title: "McCoy Portal - internals",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/internal/:id",
    name: "internal",
    component: () => import("../views/admin/internal/Detail.vue"),
    meta: {
      title: "McCoy Portal - internal",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/mcademy_items",
    name: "mcademy_items",
    component: () => import("../views/admin/mcademy_items/Overview.vue"),
    meta: {
      title: "McCoy Portal - McAdemy",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/mcademy_items/:id",
    name: "mcademy_item",
    component: () => import("../views/admin/mcademy_items/Detail.vue"),
    meta: {
      title: "McCoy Portal - McAdemy",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/mcademy_items/new",
    name: "new_mcademy_items",
    component: () => import("../views/admin/mcademy_items/New.vue"),
    meta: {
      title: "McCoy Portal - McAdemy",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/homepageitems",
    name: "Homepage items",
    component: () => import("../views/admin/homepage_items/HomepageItems.vue"),
    meta: {
      title: "McCoy Portal - HomepageItems",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/homepageitems/new",
    name: "Homepage item new",
    component: () => import("../views/admin/homepage_items/New.vue"),
    meta: {
      title: "McCoy Portal - New HomepageItem",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/homepageitems/edit/:id",
    name: "Homepage item edit",
    component: () => import("../views/admin/homepage_items/HomepageItemDetail.vue"),
    meta: {
      title: "McCoy Portal - HomepageItem",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: () => import("../views/admin/jobs/Jobs.vue"),
    meta: {
      title: "McCoy Portal - Jobs",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/messages",
    name: "Messages",
    component: () => import("../views/admin/messages/Overview.vue"),
    meta: {
      title: "McCoy Portal - Messages",
      layout: "AppLayoutPortal",
    },
  },
  {
    path: "/messages/:id",
    name: "Read message",
    component: () => import("../views/admin/messages/Detail.vue"),
    meta: {
      title: "McCoy Portal - Read Message",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/messages/new",
    name: "New message",
    component: () => import("../views/admin/messages/New.vue"),
    meta: {
      title: "McCoy Portal - New Message",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/jobs/new",
    name: "New job",
    component: () => import("../views/admin/jobs/New.vue"),
    meta: {
      title: "McCoy Portal - New Job",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/jobs/:id",
    name: "Edit job",
    component: () => import("../views/admin/jobs/JobDetail.vue"),
    meta: {
      title: "McCoy Portal - Job",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: () => import("../views/Profile.vue"),
    meta: {
      title: "McCoy Portal - Profile",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/smoelenboek/:id",
    name: "Smeoelenboek entry",
    component: () => import("../views/smoelenboek/Detail.vue"),
    meta: {
      title: "McCoy Portal - Smoelenboek",
      layout: "AppLayoutPortal",
    },
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: () => import("../views/BoardUser.vue"),
    meta: {
      layout: "AppLayoutPortal",
    },
  },
  {
    path: "/documentation",
    name: "Documentation",
    component: () => import("../views/Documentation.vue"),
    meta: {
      title: "McCoy Portal - Documentation",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/mcademy/:id",
    name: "mcademy_register",
    component: () => import("../views/mcademy/Detail.vue"),
    meta: {
      title: "McCoy Portal - McAdemy",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/manage",
    name: "Cv",
    component: () => import("../views/admin/cv/manage/Index.vue"),
    meta: {
      title: "McCoy Portal - Cv",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/:user_id?",
    name: "CvData",
    component: () => import("../views/admin/cv/Index.vue"),
    meta: {
      title: "McCoy Portal - My Cv",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/skills/:user_id",
    name: "CvSkills",
    component: () => import("../views/admin/cv/skills/Overview.vue"),
    meta: {
      title: "McCoy Portal - My skills",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/skills/:id/edit",
    name: "CvSkill",
    component: () => import("../views/admin/cv/skills/Edit.vue"),
    meta: {
      title: "McCoy Portal - My skill",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/skills/:user_id/new",
    name: "NewCvSkill",
    component: () => import("../views/admin/cv/skills/New.vue"),
    meta: {
      title: "McCoy Portal - New Skill",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/references/:user_id",
    name: "CvReferences",
    component: () => import("../views/admin/cv/references/Overview.vue"),
    meta: {
      title: "McCoy Portal - My references",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/references/:id/edit",
    name: "Cvreference",
    component: () => import("../views/admin/cv/references/Edit.vue"),
    meta: {
      title: "McCoy Portal - My skill",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/references/:user_id/new",
    name: "NewCvreference",
    component: () => import("../views/admin/cv/references/New.vue"),
    meta: {
      title: "McCoy Portal - New Skill",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/languages/:user_id",
    name: "CvLanguages",
    component: () => import("../views/admin/cv/languages/Overview.vue"),
    meta: {
      title: "McCoy Portal - My languages",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/languages/:id/edit",
    name: "Cvlanguage",
    component: () => import("../views/admin/cv/languages/Edit.vue"),
    meta: {
      title: "McCoy Portal - My language",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/languages/:user_id/new",
    name: "newlanguages",
    component: () => import("../views/admin/cv/languages/New.vue"),
    meta: {
      title: "McCoy Portal - New language",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/education/:user_id",
    name: "CvEducations",
    component: () => import("../views/admin/cv/education/Overview.vue"),
    meta: {
      title: "McCoy Portal - My languages",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/education/:id/edit",
    name: "CvEducation",
    component: () => import("../views/admin/cv/education/Edit.vue"),
    meta: {
      title: "McCoy Portal - My language",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/education/:user_id/new",
    name: "NewEducation",
    component: () => import("../views/admin/cv/education/New.vue"),
    meta: {
      title: "McCoy Portal - New language",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/training/:user_id",
    name: "CvTrainings",
    component: () => import("../views/admin/cv/training/Overview.vue"),
    meta: {
      title: "McCoy Portal - My languages",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/training/:id/edit",
    name: "CvTraining",
    component: () => import("../views/admin/cv/training/Edit.vue"),
    meta: {
      title: "McCoy Portal - My language",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/training/:user_id/new",
    name: "NewTraining",
    component: () => import("../views/admin/cv/training/New.vue"),
    meta: {
      title: "McCoy Portal - New language",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/projects/:user_id",
    name: "CvProjects",
    component: () => import("../views/admin/cv/projects/Overview.vue"),
    meta: {
      title: "McCoy Portal - My projects",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/projects/:id/edit",
    name: "EditCvProject",
    component: () => import("../views/admin/cv/projects/Edit.vue"),
    meta: {
      title: "McCoy Portal - My projects",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/projects/:user_id/new",
    name: "NewProjects",
    component: () => import("../views/admin/cv/projects/New.vue"),
    meta: {
      title: "McCoy Portal - New project",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/resumes/:user_id",
    name: "resumes",
    component: () => import("../views/admin/cv/resumes/Overview.vue"),
    meta: {
      title: "McCoy Portal - resumes",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/resumes/:user_id/:id/edit",
    name: "eresumes",
    component: () => import("../views/admin/cv/resumes/Detail.vue"),
    meta: {
      title: "McCoy Portal - resume",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/resumes/:user_id/new",
    name: "nresume",
    component: () => import("../views/admin/cv/resumes/New.vue"),
    meta: {
      title: "McCoy Portal - New resume",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/intro/:user_id",
    name: "intro",
    component: () => import("../views/admin/cv/intro/Edit.vue"),
    meta: {
      title: "McCoy Portal - Edit intro",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/clients",
    name: "clients",
    component: () => import("../views/admin/clients/Overview.vue"),
    meta: {
      title: "McCoy Portal - clients",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/clients/:id",
    name: "eclient",
    component: () => import("../views/admin/clients/Detail.vue"),
    meta: {
      title: "McCoy Portal - client",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/cv/clients/new",
    name: "nclient",
    component: () => import("../views/admin/clients/New.vue"),
    meta: {
      title: "McCoy Portal - New client",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/smoelenboek/:id",
    name: "smoelenboekEntry",
    component: () => import("../views/smoelenboek/Detail.vue"),
    meta: {
      title: "McCoy Portal - smoelenboek",
      layout: "AppLayoutPortal",
      requiresAuth: true
    },
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
  {
    path: '/failed',
    name: 'Failed',
    component: Failed
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior () {
    // return desired position
    // always scroll to top
    return { top: 0 }
  }
});

registerGuard(router);

router.beforeEach((to, from, next) => {

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));
});

export default router;
