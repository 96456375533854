import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import router from './router'
import store from './store';
import AppLayout from './layouts/AppLayout'
import InstantSearch from 'vue-instantsearch/vue3/es';
import Notification from './components/Notification';
import PageHeader from './components/PageHeader';
import mitt from 'mitt';
import './assets/tailwind.css'

import { msalPlugin } from "./plugins/msalPlugin";
import { msalInstance } from "./authConfig";
import { EventType } from "@azure/msal-browser";
import { CustomNavigationClient } from "./router/NavigationClient";

const emitter = mitt();

// The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const app = createApp(App,)
  .use(router)
  .use(store)
  .component('Notification', Notification)
  .component('PageHeader', PageHeader)
  .use(InstantSearch)
  .use(msalPlugin, msalInstance)
  .component('AppLayout', AppLayout)

app.config.globalProperties.emitter = emitter;
// app.config.globalProperties.$msalInstance = {};

app.mount('#app');