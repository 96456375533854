import { createStore } from 'vuex'

const token = localStorage.getItem('ot');
const user = JSON.parse(localStorage.getItem('user'));

// import { auth } from './auth.module';

// export default createStore({
//   modules: {
//     auth
//   }
// })

const store = createStore({
  state() {
    return {
      accessToken: token,
      user
    };
  },
  mutations :{
    setAccessToken(state, token){
      localStorage.setItem('ot', token);
      state.accessToken = token;
    },
    setUser(state, user) {
      localStorage.setItem('user', JSON.stringify(user));
      state.user = user
    }
  },
  actions: {
    set_user({commit}, user) {
      commit('setUser', user);
    },
    set_access_token({commit}, token) {
      commit('setAccessToken', token);
    }
  },
  getters: {
    user: state => {
      return state.user
    },
    accesstoken: state => {
      return state.accessToken
    }
  }
});

export default store;