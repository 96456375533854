<template>
  <div className="max-w-3xl mx-auto md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl px-0 px-6 py-4 mb-6 bg-gray-100 rounded shadow">
    <div className="flex items-center space-x-5">
      <div>
        <h1 className="text-2xl font-bold text-gray-800">{{title}}</h1>
        <p className="text-sm font-medium text-gray-400">
          {{subtitle}}
        </p>
      </div>
    </div>
    <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
      <router-link
        v-if="overview_route" 
        :to="overview_route"
        type="button"
        className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
      >
        <Menu32 class="flex-shrink-0 h-5 w-5 mr-2" aria-hidden="true" />
        Overzicht
      </router-link>
      <router-link 
        v-if="route" 
        :to="route" 
        class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-black"
      >
        Nieuw
      </router-link>
    </div>
  </div>
</template>

<script>
import { toRefs } from "vue";
import { Menu32 } from '@carbon/icons-vue';

export default {
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    route: {
      type: String
    },
    overview_route: {
      type: String
    },
  },
  components: {
    Menu32
  },
  setup(props) {
    const { title, subtitle, route } = toRefs(props);
    return {
      pageTitle: title,
      pageSubtitle: subtitle,
      pageRoute: route
    }
  },
}
</script>