<template>
  <img :src="imageSizeUrl(source)" />
</template>

<script>
export default {
  props: ['source', 'type'],
  setup(props) {
    return {
      size: props.type || '400x400x'
    }
  },
  methods: {
    imageSizeUrl(value) {
      
      var index = 0; // make this a property too
      
      
      var imageUrl = "/empty-state-illustrations.gif"; // TODO set a default url here?
      let imageKey = value.key;
      
      if (Array.isArray(value) && value.length > 0) {
        // console.log(value[index].data.key);
        imageKey = value[index].data.key;
      }
    
      if (imageKey != undefined) {
        let fileName = imageKey.substring(imageKey.lastIndexOf('/') + 1, imageKey.length);
        let filePrefix = imageKey.replace(fileName, '');
        var splitted = fileName.split('.');
        imageUrl = `${process.env.VUE_APP_CLOUDFRONT_URL}${filePrefix}${splitted[0]}_${this.size}.${splitted[1]}`
    
        return imageUrl;
      }
    
      return imageUrl;
    }
  }
}
</script>
